<template>
  <div class="lightSwitch">
    <div class="light-icon">
      <img src="./img/light-icon.png" alt="" />
    </div>
    <div class="btn">
      <img src="./img/btn.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "灯光",
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.lightSwitch {
  width: 100%;
  min-height: 100vh;
  background: #f5eeec;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .light-icon {
    width: 288px;
    height: 542px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .btn {
    width: 454px;
    height: 454px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
